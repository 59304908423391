<template>
  <article id="wrap">

    <AppHeader theme="white">FAQ</AppHeader>


    <section class="contents">

      <div class="contentInner pb_80">


        <div class="acd">
          <input id="check1" type="checkbox" class="acd-hidden">
          <label for="check1" class="acd-open">パスワードの再設定について</label>
          <label for="check1" class="acd-close">一度ログアウトしていただき、ログインページ中ほどの”パスワードを忘れた場合”をタップしパスワードの再設定を行ってください。<br>※パスワードは”わたしのこと”ページでも桁数までしか確認ができません。<br>ご注意ください。</label>
        </div>

        <div class="acd">
          <input id="check2" type="checkbox" class="acd-hidden">
          <label for="check2" class="acd-open">個人のホームページやブログ、印刷物に、サイト内の画像や音源、映像を使用してもいいですか？</label>
          <label for="check2" class="acd-close">『すてきなせかい』内のテキスト・画像・映像・音声・イラスト等については、個人サイト・ブログへの転載、画像等を転写・加工しての無断使用及び、第三者への転送等は固く禁じております。<br>他サイト等での転載を発見された場合は、当サイトの [ お問い合わせ ] ページよりご連絡ください。</label>
        </div>

        <div class="acd">
          <input id="check3" type="checkbox" class="acd-hidden">
          <label for="check3" class="acd-open">会費を払わなくても、コンテンツを見ることはできますか？</label>
          <label for="check3" class="acd-close">『すてきなせかい』は有料会員向けコンテンツになっております。<br>会員様以外のお客様はコンテンツを閲覧できません。</label>
        </div>

        <div class="acd">
          <input id="check4" type="checkbox" class="acd-hidden">
          <label for="check4" class="acd-open">購読期限が切れそうです。どうしたら購読を継続できますか？</label>
          <label for="check4" class="acd-close">購読の継続は自動更新です。<br>退会手続きをしない限り、購読期限に現在のプラン料金の決済が行われ、購読期間が更新されます。<br>残高不足やカード期限切れ等による決済不履行がございますと、購読が継続されず退会になることがございますので、購読期限前にご確認ください。</label>
        </div>

        <div class="acd">
          <input id="check5" type="checkbox" class="acd-hidden">
          <label for="check5" class="acd-open">登録しているクレジットカード情報を変更したい</label>
          <label for="check5" class="acd-close">クレジットカード情報の変更には加入しているマスターのトークルームから退会していただき、再度参加申請が必要となります。その際に新しいクレジットカード情報をご記載ください。<br>※プロフィールのファン歴日数や過去のトーク、アルバムなどの情報は全てリセットされます。ご了承ください。</label>
        </div>

        <div class="acd">
          <input id="check6" type="checkbox" class="acd-hidden">
          <label for="check6" class="acd-open">投稿したコメントを編集/削除したい</label>
          <label for="check6" class="acd-close">該当するコメントの右下”・・・”をタップし、編集/削除を行ってください。</label>
        </div>

        <div class="acd">
          <input id="check7" type="checkbox" class="acd-hidden">
          <label for="check7" class="acd-open">トーク内で不適切な発言がありました</label>
          <label for="check7" class="acd-close">該当するコメントの右下”・・・”をタップし、”メッセージを通報する”をタップしてください。<br>一定数の通報がありますと該当コメントは削除されます。</label>
        </div>

        <div class="acd">
          <input id="check8" type="checkbox" class="acd-hidden">
          <label for="check8" class="acd-open">不具合が発生しています</label>
          <label for="check8" class="acd-close">一度、ブラウザを閉じていただき再度『すてきなせかい』へアクセスを行ってください。<br>不具合が再度、発生する場合は[ お問い合わせ ] ページより症状を記載の上ご連絡ください。</label>
        </div>

        <div class="acd">
          <input id="check9" type="checkbox" class="acd-hidden">
          <label for="check9" class="acd-open">FAQで問題が解決しません</label>
          <label for="check9" class="acd-close">FAQでお客様のお困りな事への記載がない場合、[ お問い合わせ ] ページより ご連絡ください。<br>こちらからお問い合わせいただいた場合、返信までにお時間をいただく場合がございますので、予めご了承下さい。</label>
        </div>

        <div class="acd">
          <input id="check10" type="checkbox" class="acd-hidden">
          <label for="check10" class="acd-open">退会方法を教えてください</label>
          <label for="check10" class="acd-close">ページ下部の退会からお手続きをお願いいたします。</label>
        </div>

        <div class="acd">
          <input id="check11" type="checkbox" class="acd-hidden">
          <label for="check11" class="acd-open">商品の注文ができたかどうか確認はできますか？</label>
          <label for="check11" class="acd-close">ご注文完了後、24時間以内に確認メールをお客様が入力されたメールアドレスにお送りします。このメールが届かない場合は、メールサーバーの不具合か、ご注文が完了していない可能性がございます。詳しくはこ[お問い合わせ]ページよりご連絡ください。<br>ご注文の商品に関してはログイン後わたしのこと内のご注文履歴よりご確認いただけます。注文したはずなのにご注文履歴に表示されていない場合、正しくご注文が完了しておりません。恐れ入りますが、[お問い合わせ]ページよりご連絡ください。</label>
        </div>

        <div class="acd">
          <input id="check12" type="checkbox" class="acd-hidden">
          <label for="check12" class="acd-open">商品を個以上注文すると送料はどうなる？</label>
          <label for="check12" class="acd-close">1度のご注文につき、注文個数にかかわらず、全国一律700円です。また、一部商品につきましては送料が上記金額を越えるものもあります。詳しくは商品紹介ページにてご確認ください。</label>
        </div>

        <div class="acd">
          <input id="check13" type="checkbox" class="acd-hidden">
          <label for="check13" class="acd-open">商品の購入で使えるクレジットカード会社は？</label>
          <label for="check13" class="acd-close">ご利用頂けるクレジットカードはVISA、Master、JCBのマークがプリントされているカードになります。<br>※お支払回数はご注文欄にて、一括払い、分割払い、リボ払いのいずれかをお選びください。</label>
        </div>

        <div class="acd">
          <input id="check14" type="checkbox" class="acd-hidden">
          <label for="check14" class="acd-open">商品の交換・返品は？</label>
          <label for="check14" class="acd-close">返品、交換ご希望の方は、商品到着より７日以内に、[お問い合わせ]までご連絡ください。</label>
        </div>

        <div class="acd">
          <input id="check15" type="checkbox" class="acd-hidden">
          <label for="check15" class="acd-open">不良品や注文していない商品が届いた場合は？</label>
          <label for="check15" class="acd-close">速やかに交換・返品の対応をさせていただきます。商品の到着から1週間以内に、[お問い合わせ]までご連絡ください。</label>
        </div>

        <div class="acd">
          <input id="check16" type="checkbox" class="acd-hidden">
          <label for="check16" class="acd-open">サイズが合わなかったり、色などのイメージが違っていた場合は、交換や返品ができる？</label>
          <label for="check16" class="acd-close">商品到着後1週間以内の未使用品に限り、ご返品を承っております。お客様のご都合による返品の際の送料は、お客様にご負担いただきます。また、色の変更も含めたほかの商品への交換は承っておりません。また、お客さまから事前のご連絡がなく、イメージ違い等で商品を返品された場合、送料を請求させていただくことがございます。ご了承下さい。</label>
        </div>

        <div class="acd">
          <input id="check17" type="checkbox" class="acd-hidden">
          <label for="check17" class="acd-open">海外へ届けることはできる？</label>
          <label for="check17" class="acd-close">お届け先は日本国内のみに限らせていただきます。ご了承ください。</label>
        </div>

        <div class="acd">
          <input id="check18" type="checkbox" class="acd-hidden">
          <label for="check18" class="acd-open">自宅ではなく勤務先に送ってもらうことはできる？</label>
          <label for="check18" class="acd-close">対応しております。ご注文時に送りたいご住所をご入力ください。</label>
        </div>

        <div class="acd">
          <input id="check19" type="checkbox" class="acd-hidden">
          <label for="check19" class="acd-open">デジタルコンテンツの購入キャンセルはできますか？</label>
          <label for="check19" class="acd-close">デジタルコンテンツの購入後のキャンセルはできません。ご了承ください。</label>
        </div>

      </div><!--.contentInner-->

    </section><!--.contents-->


    <AppFooter fixed theme=""></AppFooter>

  </article>
</template>

<script>
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
export default {
  name: 'FAQ',
  components: {AppHeader, AppFooter}
}
</script>
